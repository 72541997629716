import { graphql, Link } from 'gatsby';
import { kebabCase } from 'lodash';
import React from 'react';
import Helmet from 'react-helmet';

import { AllTagsQuery } from '~types/graphql-types';

import Layout from '../../components/Layout';

interface AllTagsPageProps {
  group: AllTagsQuery['allMarkdownRemark']['group'];
  title: string;
}
/**
 * Renders a page for all existing tags
 */
const AllTagsPage = ({ group, title }: AllTagsPageProps): JSX.Element => (
  <Layout>
    <section className="section">
      <Helmet title={`Etiquetas | ${title}`} />
      <div className="container content">
        <div className="columns">
          <div className="column is-10 is-offset-1" style={{ marginBottom: '6rem' }}>
            <h1 className="title is-size-2 is-bold-light">Etiquetas</h1>
            <ul className="taglist">
              {group.map(tag => (
                <li key={tag.fieldValue!}>
                  <Link to={`/tags/${kebabCase(tag.fieldValue!)}/`}>
                    {tag.fieldValue} ({tag.totalCount})
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  </Layout>
);

interface AllTagsPageContainerProps {
  data: AllTagsQuery;
}

/**
 * Maps props for AllTagsPage
 */
const AllTagsPageContainer = ({ data }: AllTagsPageContainerProps): JSX.Element => {
  if (!data.site || !data.site.siteMetadata || !data.site.siteMetadata.title) {
    throw new Error('Invalid siteMetadata');
  }

  return <AllTagsPage group={data.allMarkdownRemark.group} title={data.site.siteMetadata.title} />;
};

export default AllTagsPageContainer;

export const allTagsPageQuery = graphql`
  query AllTags {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(limit: 1000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`;
